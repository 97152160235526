import React from "react";
export const NMARKERS = 230;
export const MARKERWIDTH =
  window.innerWidth > window.innerHeight
    ? window.innerWidth * 0.02
    : window.innerHeight * 0.03;
export const MARKERSPACING = MARKERWIDTH * 4;
export const SPEED = 6;
export const STARTSHIFT = window.innerHeight * 0.75;
let YTIMELINE = window.innerHeight * 0.6;

export const generateSVGElements = () => {
  YTIMELINE = window.innerHeight * 0.6;
  return [
    ...generateTexts(),
    getFirstCircle(),
    getClipCirlcle(),
    ...generateTimeLine(),
    // getLastCircle(),
  ];
};

const getDefaultCircle = () => {
  const circle = {
    domType: "circle",
    animType: "defaultCircle",
    ref: React.createRef(),
    cx: window.innerWidth / 2,
    cy: window.innerHeight / 2,
    r: window.innerWidth / 3,
    strokewidth: 12,
    stroke: "rgba(145,254,175,255)",
    fill: "transparent",
  };

  return circle;
};
const getDefaultText = () => {
  const text = {
    domType: "text",
    animType: "defaultText",
    ref: React.createRef(),
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
    fontSize: 0,
    fill: "rgba(145,254,175,255)",
    content: "Hello World",
    textAnchor: "middle",
  };
  return text;
};
const getClipCirlcle = () => {
  const circle = {
    ...getDefaultCircle(),
    domType: "clipCircle",
    animType: "mainCircle",
  };

  return circle;
};
const getFirstCircle = () => {
  const circle = {
    ...getDefaultCircle(),
    animType: "mainCircle",
  };

  return circle;
};
const getLastCircle = () => {
  const circle = {
    ...getDefaultCircle(),
    animType: "lastCircle",
    domType: "lastCircle",
    fill: "white",
  };

  return circle;
};

const generateTimeLine = () => {
  const timeLines = [];
  const isVertical = window.innerWidth < window.innerHeight;
  for (let i = 0; i < NMARKERS; i++) {
    if (isVertical) {
      timeLines.push({
        domType: "line",
        animType: "hline",
        idx: i,
        ref: React.createRef(),
        x1: window.innerWidth * 0.9,
        y1: window.innerHeight * 0.8,
        x2: window.innerWidth * 0.9,
        y2: window.innerHeight * 0.8,
        stroke: "black",
        strokeWidth: 0.75,
      });
    } else {
      timeLines.push({
        domType: "line",
        animType: "hline",
        idx: i,
        ref: React.createRef(),
        x1: window.innerWidth * 0.9,
        y1: YTIMELINE,
        x2: window.innerWidth * 0.9,
        y2: YTIMELINE,
        stroke: "black",
        strokeWidth: 0.75,
      });
    }
  }
  let annotationIdx = 0;
  for (let i = 0; i < NMARKERS; i++) {
    let lengthMultiplier = (i + 26) % 11 === 0 ? 4 : 1;
    lengthMultiplier = (i + 26) % 33 === 0 ? 11 : lengthMultiplier;
    if (i === 0) lengthMultiplier = 1;
    const minusLength = lengthMultiplier === 1 ? 1 : 2.5;
    if (lengthMultiplier === 11) {
      timeLines.push(getAnnotationCircle(i));
      annotationIdx++;
    }
    if (isVertical) {
      timeLines.push({
        domType: "line",
        animType: "vline",
        idx: i,
        ref: React.createRef(),
        x1: window.innerWidth * 0.9,
        y1: window.innerHeight * 0.8 + 10,
        x2: window.innerWidth * 0.9,
        y2: window.innerHeight * 0.8 + 10,
        length: window.innerHeight * 0.01 * lengthMultiplier,
        minusLength: window.innerHeight * 0.01 * minusLength,
        stroke: "black",
        strokeWidth: 0.75,
      });
    } else {
      timeLines.push({
        domType: "line",
        animType: "vline",
        idx: i,
        ref: React.createRef(),
        x1: window.innerWidth * 0.9,
        y1: YTIMELINE,
        x2: window.innerWidth * 0.9,
        y2: YTIMELINE,
        length: window.innerHeight * 0.01 * lengthMultiplier,
        minusLength: window.innerHeight * 0.01 * minusLength,
        stroke: "black",
        strokeWidth: 0.75,
      });
    }
  }
  return timeLines;
};

const generateTexts = () => {
  const timeLines = [];
  let annotationIdx = 0;
  for (let i = 0; i < NMARKERS; i++) {
    let lengthMultiplier = (i + 26) % 11 === 0 ? 4 : 1;
    lengthMultiplier = (i + 26) % 33 === 0 ? 11 : lengthMultiplier;
    if (i === 0) lengthMultiplier = 1;
    if (lengthMultiplier === 11) {
      timeLines.push(...getAnnotationText(i, annotationIdx));
      annotationIdx++;
    }
  }
  return timeLines;
};

const getAnnotationCircle = (idx) => {
  return {
    ...getDefaultCircle(),
    idx: idx,
    domType: "smallCircle",
    animType: "annotationCircle",
    r: 0,
    cy: YTIMELINE - window.innerHeight * 0.01 * 11,
    strokewidth: 1,
    stroke: "black",
    fill: "black",
  };
};

export const textAnnotations = [
  {
    title: "Antikythera Mechanism",
    text: "The Antikythera Mechanism serves as a complex celestial computer, elsewhere, some philosophers make homes in barrels.",
    lines: [
      "The Antikythera Mechanism",
      "serves as a complex celestial computer",
      "in a world where some philosophers",
      "make homes in barrels.",
    ],
    year: "253BC",
  },
  {
    title: "Baghdad Battery",
    text: "The Baghdad Battery an ancient source of electricity, exists in a world where people navigate by the light of oil lamps.",
    lines: [
      "The Baghdad Battery",
      "an ancient source of electricity exists",
      "in a time where some people navigate",
      "by the light of oil lamps.",
    ],
    year: 249,
  },
  {
    title: "Uunartoq Disc",
    text: "The Uunartoq Disc a tool for Viking navigation, is used in an era when maps of the world remain largely uncharted and mythical.",
    lines: [
      "The Uunartoq Disc",
      "a tool for Viking navigation",
      "in a world where a flat earth ",
      "was thought as center of the universe.",
    ],
    year: 1013,
  },
  {
    title: "Da Vinci’s Studies",
    text: "Leonardo da Vinci delves into the intricacies of human anatomy and the mechanics of flight, at a time when many believe in the four humors as the basis of medicine.",
    lines: [
      "Da Vinci’s Scientific Studies",
      "delve into the intricacies of human anatomy",
      "in a time where the four humors",
      "were the basis of medicine.",
    ],
    year: 1502,
  },
  {
    title: "Noether's Theorems",
    text: "Emmy Noether revolutionizes physics with her theorems on symmetries in a world where the symmetry of gender remains an unsolved equation.",
    lines: [
      "Emmy Noether revolutionizes physics",
      "with her theorems on symmetries",
      "in a world where the symmetry of",
      "gender remains an unsolved equation.",
    ],
    year: 1915,
  },
  {
    title: "Last Guillotine Execution",
    text: "The last guillotine execution in France, a form of entertainment concludes, in a year where Star Wars: A New Hope ushers in a new era of storytelling.",
    lines: [
      "The last execution by Guillotine",
      "in France, a form of entertainment concludes",
      "in a time where Star Wars A New Hope",
      "ushers in a new era of storytelling.",
    ],
    year: 1977,
  },
  {
    title: "GPT Emergence",
    text: "GPT is emerging the world is questioning its consciousness the Swiss Federal Office communicates via fax to confirm COVID infections.",
    lines: [
      "GPT and large language models are emerging",
      "the world is questioning their consciousness",
      "in a time where the Swiss Federal Office",
      "communicates via fax to confirm COVID infections.",
    ],
    year: 2020,
  },
];

const getAnnotationText = (markerIdx, annotationIdx) => {
  if (annotationIdx >= textAnnotations.length) return [];
  const isVertical = window.innerWidth < window.innerHeight;
  if (isVertical) {
    return [
      {
        ...getDefaultText(),
        content: textAnnotations[annotationIdx].title,
        idx: markerIdx,
        animType: "annotationText",
        r: 0,
        y: YTIMELINE - window.innerHeight * 0.01 * 2,
        fontSize: Math.min(30, window.innerWidth * 0.045),
        strokewidth: 1,
        stroke: "black",
        fill: "black",
      },
      {
        ...getDefaultText(),
        content: textAnnotations[annotationIdx].year,
        idx: markerIdx,
        animType: "annotationNumber",
        r: 0,
        y: YTIMELINE + window.innerHeight * 0.17,
        strokewidth: 23,
        fontSize: window.innerWidth * 0.12,
        stroke: "rgba(145,254,175,255)",
        fill: "rgba(145,254,175,255)",
      },
    ];
  }
  return [
    {
      ...getDefaultText(),
      content: textAnnotations[annotationIdx].title,
      idx: markerIdx,
      animType: "annotationText",
      r: 0,
      y: YTIMELINE - window.innerHeight * 0.01 * 12,
      fontSize: Math.min(20, window.innerWidth * 0.04),
      strokewidth: 1,
      stroke: "black",
      fill: "black",
    },
    {
      ...getDefaultText(),
      content: textAnnotations[annotationIdx].year,
      idx: markerIdx,
      animType: "annotationNumber",
      r: 0,
      y: YTIMELINE + window.innerHeight * 0.1,
      fontSize: window.innerWidth * 0.05,
      strokewidth: 23,
      stroke: "rgba(145,254,175,255)",
      fill: "rgba(145,254,175,255)",
    },
  ];
};
