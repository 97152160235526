import React, { useEffect, useState } from "react";
import Cross from "../assets/cross.svg"; // Import the SVG file
import "./Footer.css";

function Footer() {
  const [iconWidth, setIconWidth] = useState(
    Math.min(Math.max(window.innerHeight, window.innerWidth) * 0.05, 40)
  );

  useEffect(() => {
    // Optionally, add a resize listener if you want dynamic icon size.
  }, []);

  const textStyle = {
    fontSize: `${iconWidth / 3}px`,
    textDecoration: "none",
    color: "inherit",
  };

  return (
    <div className="footerContainer">
      <div className="leftButton">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", zIndex: 200 }}>
          <img src={Cross} alt="Cross" style={{ width: `${iconWidth}px` }} />
        </div>
      </div>
      <div className="rightButton">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", zIndex: 200 }}>
          <img src={Cross} alt="Cross" style={{ width: `${iconWidth}px` }} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
