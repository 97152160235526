import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { label: "Home", to: "/", external: false },
    { label: "About", to: "/about", external: false },
    // { label: "Projects", to: "https://projects.emergingevidence.ch", external: true },
    { label: "Contact", to: "/contact", external: false },
  ];

  const renderNavItem = (item) => {
    // For internal links, check if the current location matches the link
    const isActive = !item.external && location.pathname === item.to;
    const className = `nav-item ${isActive ? "active" : ""}`;
    if (item.external) {
      return (
        <a href={item.to} className={className} target="_blank" rel="noopener noreferrer">
          {item.label}
        </a>
      );
    } else {
      return (
        <Link to={item.to} className={className}>
          {item.label}
        </Link>
      );
    }
  };

  return (
    <nav className="navigation">
      <div className="nav-container">
        {/* Desktop: show inline nav items */}
        <div className="nav-items desktop-menu">
          {navItems.map((item, index) => (
            <div key={index} className="nav-item-wrapper">
              {renderNavItem(item)}
            </div>
          ))}
        </div>

        {/* Mobile: show hamburger icon */}
        <div className="nav-menu-icon mobile-menu" onClick={toggleMenu}>
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        {/* Mobile dropdown (only when open) */}
        {isMenuOpen && (
          <div className="nav-items mobile-menu-dropdown">
            {navItems.map((item, index) => (
              <div
                key={index}
                className="nav-item-wrapper"
                onClick={() => setIsMenuOpen(false)}
              >
                {renderNavItem(item)}
              </div>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
